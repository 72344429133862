
.span-modal:hover {
    color: #cd2d33
}

.span-modal {
    color: rgb(205 45  51 / 0.8 );
    cursor: pointer;
    
}
