.card__contend {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 10px;
}

.card__contend2 {
  display: flex;
  gap: 5px;
  justify-content: space-around;
  /* align-items: center; */
}

.card__contend:last-child {
  align-items: center;
}

.cards {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.servicios {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}
.servicios h2 {
  color: #ca2228;
}
.card__contend p {
  text-align: center;
  padding: 0;
  margin: 0;
}

.card__carretero {
  height: auto;
}
