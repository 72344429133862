
.float {
    position: fixed;
    bottom: 40px;
    right: 40px;
    text-align: center;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
}

.my-float {
    margin-top: 22px;
}