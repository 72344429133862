.messageIn {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: 57px;
  padding-left: 71px;
}

.messageContainer {
  margin-bottom: 0;
  border-radius: 7.5px;
  max-width: 75%;
  position: relative;
  flex: none;
  font-size: 14.2px;
  line-height: 19px;
  color: white;
}

.messageContainerSpan {
  color: #fff;
  left: -8px;
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
}

.profilePicture {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  left: -38px;
  height: 28px;
  position: absolute;
  width: 28px;
}

.profilePicture img {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: block;
  opacity: 1;
}

.messageSquareContainer {
  background-color: white;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  position: relative;
  z-index: 200;
}

.messageContainerElements {
  padding-right: 8px;
  padding-left: 9px;
  padding-bottom: 8px;
  padding-top: 6px;
  box-sizing: border-box;
}

.messageNamePersona {
  display: inline-flex;
  max-width: 100%;
  font-size: 12.8px;
  line-height: 22px;
  font-weight: 500;
  color: #e542a3;
}

.messageNamePersona span {
  padding-left: 2px;
  margin-left: -2px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messageContainerText {
  color: #111b21;
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.messageContainerTextSpan {
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 0;
  font-size: 0.6875rem;
  vertical-align: middle;
  visibility: hidden;
  display: inline-flex;
  padding-top: 0;
  line-height: 15px;
  height: 0;
}

.messageContainerHora {
  margin-left: 4px;
  margin-right: 0px;
  z-index: 10;
  position: relative;
  float: right;
  margin-bottom: -5px;
  margin-top: -10px;
}

.messageContainerHora div {
  display: flex;
  font-size: 0.6875rem;
  white-space: nowrap;
  color: #667781;
  align-items: center;
  height: 15px;
  line-height: 15px;
}

.messageContainerHora div span {
  display: inline-block;
  vertical-align: top;
}
