.main-chat {
  display: block;
  place-items: center;
}

.header-chat {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  background: #f0f2f5;
  padding: 10px 16px;
}

.header-titles {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.chat-subtitle {
  color: #8696a0;
  font-size: 0.8125rem;
}

.avatar-chat {
  margin-right: 10px;
}

.messages-chat {
  min-height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  background: #efeae2;
  background-image: url("/public/bg-chat-tile.png");
  background-repeat: repeat;
}

.messages-chat::-webkit-scrollbar {
  display: none;
}

.footer-chat {
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  background: #f0f2f5;
  padding: 0px 0px 10px 15px;
}
