.card-services {
    border-radius: 15px;
    font-size: 50px;
    text-align: center;
    background-color: green;
    padding: 25px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item_hdi {
    cursor: pointer;
    text-align: center;
}

.item_hdi:hover {
    color: #1d39c4;
}