.titulo {
  margin-left: 15px;
  color: var(--color-naranja);
  font-weight: 500;
  font-size: 20px;
}

.container-listado {
  display: flex;
  width: 100%;
  max-height: 520px;
  min-height: 520px;
  margin-left: 5px;
  margin-top: 12px;
}

.listado-chats {
  width: 25%;
  overflow-y: scroll;
  direction: rtl;
  background: white;
  max-height: 620px;
  min-height: 620px;
}

.listado-chats::-webkit-scrollbar {
  display: none;
}
