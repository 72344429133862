.flex {
    display: flex;
    gap: 1rem;
}

.card {
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    padding: 40px;
}
.gnp {
    background-color: #001149;
}

.gnp:hover {
    /*tono mas claro*/
    background-color: #2648bb;
}

.hdi {
    background: #006729;
}

.hdi:hover {
    /*tono mas claro*/
    background-color: #008c3e;
}