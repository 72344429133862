.messageOut {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 57px;
  padding-left: 71px;
}

.messageContainer {
  margin-bottom: 0;
  border-radius: 7.5px;
  max-width: 75%;
  position: relative;
  flex: none;
  font-size: 14.2px;
  line-height: 19px;
  color: white;
}

.messageContainerSpan {
  color: #d9fdd3;
  right: -8px;
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
}

.messageSquareContainer {
  background-color: #d9fdd3;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  position: relative;
  z-index: 200;
}

.messageContainerElements {
  padding-right: 8px;
  padding-left: 9px;
  padding-bottom: 8px;
  padding-top: 6px;
  box-sizing: border-box;
}

.messageContainerText {
  color: #111b21;
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.messageContainerTextSpan {
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 0;
  font-size: 0.6875rem;
  vertical-align: middle;
  visibility: hidden;
  display: inline-flex;
  padding-top: 0;
  line-height: 15px;
  height: 0;
}

.messageContainerHora {
  margin-left: 4px;
  margin-right: 0px;
  z-index: 10;
  position: relative;
  float: right;
  margin-bottom: -5px;
  margin-top: -10px;
}

.messageContainerHora div {
  display: flex;
  font-size: 0.6875rem;
  white-space: nowrap;
  color: #667781;
  align-items: center;
  height: 15px;
  line-height: 15px;
}

.messageContainerHora div span {
  display: inline-block;
  vertical-align: top;
}
